import './App.css';
import Login from './pages/login';
import Home from './pages/home';
import SignUp from "./pages/signup";
import GenerateForm from "./pages/generate-form/page";
import { Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './utils/AuthContext';
import ProtectedRoute from "./utils/ProtectedRoute";
import withAuth from './utils/withAuth';
import './styles/transition.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Header from './components/layout/header';
import { ConfigProvider } from 'antd';
import React from 'react';

const defaultData = {
    borderRadius: 6,
    colorPrimary: '#000000',
    Button: {
        colorPrimary: '#000000',
    },
};

// 路由配置
function App() {
    const location = useLocation();
    const showHeader = !['/login', '/signup'].includes(location.pathname);

    // 打印路径以进行调试
    console.log('Current path:', location.pathname);
    console.log('Show Header:', showHeader);

    const [data, setData] = React.useState(defaultData);

    return (
        <div className='h-full bg-mobile-bg sm:bg-tablet-bg md:bg-desktop-bg bg-cover bg-center'>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: data.colorPrimary,
                        borderRadius: data.borderRadius,
                    },
                    components: {
                        Button: {
                            colorPrimary: data.Button?.colorPrimary,
                        },
                        Message: {
                            contentBg: '#efefef'
                        }
                    },
                }}
            >
                <AuthProvider>
                    <div className="flex flex-col h-full">
                        {showHeader && <Header />}
                        <div className='flex-1 min-h-0 md:min-h-0'>
                            <TransitionGroup className="h-full max-h-full">
                                <CSSTransition
                                    key={location.key}
                                    classNames="fade h-full max-h-full"
                                    timeout={300}
                                >
                                    <Routes location={location}>
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/signup" element={<SignUp />} />
                                        <Route path="/" element={<ProtectedRoute component={Home} />} />
                                        <Route path="/generate-form" element={<ProtectedRoute component={GenerateForm} />} />
                                    </Routes>
                                </CSSTransition>
                            </TransitionGroup>
                        </div>
                    </div>
                </AuthProvider>
            </ConfigProvider>
        </div>
    );
}

export default withAuth(App);
