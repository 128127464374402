import { useContext, useRef, useState, useEffect } from 'react';
import AuthContext from "../../utils/AuthContext";
import ChatBubble from '../../components/chat-bubble/page'
import axiosInstance from '../../utils/request';
import axios from 'axios';
import DID_API from './api.json';
import { message, Spin } from 'antd';
import createFormattedDate from '../../utils/date'
import { useNavigate } from 'react-router-dom'
import waiting from '../../assets/waiting.png'
import './home.mudule.css'
export default function Home() {

    const context = useContext(AuthContext)
    const navigate = useNavigate();

    const [loading, isLoading] = useState(true);
    const [done, setDone] = useState(null);
    const [generateForm, setGenerateForm] = useState({});
    const [peerConnection, setPeerConnection] = useState(null);
    const [statsIntervalId, setStatsIntervalId] = useState(null);
    const [videoIsPlaying, setVideoIsPlaying] = useState(false);
    const [history, setHistory] = useState([]);
    // 引用变量，用于直接访问 DOM 元素
    const agentIdRef = useRef('');
    const chatIdRef = useRef('');
    const idleVideoRef = useRef('');
    const idleUrlRef = useRef('');
    const streamIdRef = useRef('');
    const sessionIdRef = useRef('');
    const lastBytesReceivedRef = useRef(0);
    const messageRef = useRef(null);
    const videoElementRef = useRef(null);
    const streamRef = useRef(null);
    const chatContainerRef = useRef(null);

    useEffect(() => {
        // 直接在useEffect中执行异步操作
        fetchData();
        loadHistory()
    }, []);


    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }, [history]);


    const fetchData = async () => {
        try {
            const response = await axiosInstance.get('/generate-form/mine');
            const { code, data } = response.data;

            if (code === 200) {

                if (!data) {
                    message.error('You have no form to generate, please wait for the Muse to generate one for you.');
                    console.log('You have no form to generate, please wait for the Muse to generate one for you.')
                    navigate('/generate-form')
                }

                setGenerateForm(data);
                if (data.isComplete === 1) {
                    setDone(true);
                    agentIdRef.current = data.agentId;
                    idleVideoRef.current = data.idleVideo;
                    idleUrlRef.current = data.idleUrl;


                    setTimeout(() => { // 过1s给个背景图
                        videoElementRef.current.style.backgroundImage = `url(${data.idleUrl})`;
                        videoElementRef.current.style.backgroundSize = 'contain';  // 可根据需要调整背景图片的尺寸适应 
                    }, 1000);


                    // 创建连接并处理
                    await createChat();
                    // await handleConnect();
                    console.log('user generate form is completed');
                } else {
                    setDone(false)
                    isLoading(false)
                }
            } else {
                console.warn(`Unexpected response code: ${code}`);
            }
        } catch (error) {
            console.error('An error occurred:', error.message);
        }
    };

    const loadHistory = async () => {
        try {
            const res = await axiosInstance.get('/chat/history');
            console.log('his', res);
            const { code, data } = res.data;
            if (code === 200) {
                setHistory(data);
            }
            console.log('history', history); // 这里可能不会立即看到更新后的值
        } catch (error) {
            console.error('Error loading history:', error);
        }
    }

    // 生命周期钩子，在组件首次加载时执行
    useEffect(() => {
        // 检查 agentIdRef 和 chatIdRef 是否已设置
        if (!agentIdRef.current || !chatIdRef.current) {
            // 提示用户进行操作
            console.log(`
          1. 点击 'Create new Agent with Knowledge' 按钮
          2. 打开控制台并等待过程完成
          3. 按下 'Connect' 按钮并发送消息
          4. 保存 'agentID' 和 'chatId' 供未来使用
        `);
        } else {
            // 显示已准备好并提供 agentId 和 chatId
            console.log(`
          准备就绪！按下 'Connect' 按钮并发送消息
          Agent ID: ${agentIdRef.current} Chat ID: ${chatIdRef.current}
        `);
        }

        // 返回 cleanup 函数，关闭可能的连接
        return () => {
            if (peerConnection) {
                closePC();
            }
        };
    }, [agentIdRef, chatIdRef]);

    useEffect(() => {
        console.log('视频状态变化', videoIsPlaying)
        if (!videoIsPlaying) {
            playIdleVideo()
        }

        if (videoIsPlaying) {
            const remoteStream = streamRef.current;
            setVideoElement(remoteStream);
        } else {
            playIdleVideo();
        }
    }, [videoIsPlaying])

    const playIdleVideo = () => {
        const videoElement = videoElementRef.current;
        if (videoElement) {
            console.log('playIdleVideo')
            videoElement.classList.toggle("animated")

            videoElement.srcObject = null;
            videoElement.loop = true;
            videoElement.play().catch(console.error);

            // Remove Animation Class after it's completed
            setTimeout(() => {
                videoElement.classList.remove("animated")
            }, 1000);
        }
    };

    async function closePC() {
        if (peerConnection) {
            peerConnection.close();
            clearInterval(statsIntervalId);
            setStatsIntervalId(null);
            setPeerConnection(null);
        }
    };

    function setVideoElement(stream) {
        if (!stream) return;

        // Add Animation Class
        videoElementRef.current.classList.add("animated")

        videoElementRef.current.muted = false;

        videoElementRef.current.srcObject = stream;
        videoElementRef.current.loop = false;


        // Remove Animation Class after it's completed
        setTimeout(() => {
            videoElementRef.current.classList.remove("animated")
        }, 1000);

        // safari hotfix
        if (videoElementRef.current.paused) {
            videoElementRef.current
                .play()
                .then((_) => { })
                .catch((e) => { });
        }
    }

    const createPeerConnection = async (offer, iceServers) => {
        const pc = new RTCPeerConnection({ iceServers });

        pc.onicecandidate = (event) => {
            console.log('onicecandidate event')
            if (event.candidate) {
                const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

                // WEBRTC API CALL 3 - Submit network information
                fetch(`${DID_API.url}/${DID_API.service}/streams/${streamIdRef.current}/ice`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Basic ${DID_API.key}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        candidate,
                        sdpMid,
                        sdpMLineIndex,
                        session_id: sessionIdRef.current,
                    }),
                });
            }
        }

        // 设置事件监听器
        pc.onicegatheringstatechange = (e) => {
            console.log('ICE Gathering State:', pc.iceGatheringState);
        };
        pc.oniceconnectionstatechange = (e) => {
            console.log('ICE Connection State:', pc.iceConnectionState);
            if (pc.iceConnectionState === 'connected') {
                isLoading(false) // 全部连接成功
            }
            if (pc.iceConnectionState === 'failed' || pc.iceConnectionState === 'closed') {
                closePC();
            }
            if (pc.iceConnectionState === 'disconnected') {
                closePC();
                // 创建连接并处理
                createChat();
            }
        };
        pc.onsignalingstatechange = (e) => {
            console.log('Signaling State:', pc.signalingState);
        };
        pc.ontrack = (event) => {

            if (!event.track) return;

            streamRef.current = event.streams[0];

            setStatsIntervalId(
                setInterval(() => {
                    pc.getStats(event.track).then(stats => {
                        // console.log('stats', stats)
                        stats.forEach((report) => {
                            // console.log('report', report)
                            if (report.type === 'inbound-rtp') {
                                console.log('inbound-rtp', report)
                            }
                            if (report.type === 'inbound-rtp' && report.kind === 'video' && report.bytesReceived !== 0) {
                                console.log('repost', report.bytesReceived)
                                const bytesDelta = report.bytesReceived - lastBytesReceivedRef.current;
                                const videoStatusChanged = Math.abs(bytesDelta) > 0; // 如果字节数有增加或减少，表示视频状态变化
                                if (videoStatusChanged) {
                                    const isVideoPlaying = bytesDelta > 0; // 如果新增字节数大于0，表示视频正在播放
                                    setVideoIsPlaying(isVideoPlaying);
                                } else {
                                    setVideoIsPlaying(false);
                                }
                                lastBytesReceivedRef.current = report.bytesReceived;
                            }
                        });
                    }).catch(error => console.error('Error getting stats:', error));
                }, 500)
            );
        };

        await pc.setRemoteDescription(offer);
        console.log('set remote sdp OK');
        const answer = await pc.createAnswer();
        console.log('create local sdp OK');
        await pc.setLocalDescription(answer);
        console.log('set local sdp OK');

        // 保活策略
        const dataChannel = pc.createDataChannel('keepAliveChannel');
        function sendPing() {
            if (dataChannel.readyState === 'open') {
                dataChannel.send(JSON.stringify({ type: 'ping' }));
            }
        }

        // 每5秒发送一次 Ping 消息
        setInterval(sendPing, 5000);

        dataChannel.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'ping') {
                console.log('Received ping');
            }
        };

        dataChannel.onopen = () => {
            console.log('keep alive Data channel opened')
        };


        // Data Channel creation (for dispalying the Agent's responses as text)
        let dc = pc.createDataChannel("JanusDataChannel");
        dc.onopen = () => {
            console.log("datachannel open");
        };

        let decodedMsg;
        // Agent Text Responses - Decoding the responses, pasting to the HTML element
        dc.onmessage = (event) => {
            let msg = event.data
            let msgType = "chat/answer:"
            if (msg.includes(msgType)) {
                msg = decodeURIComponent(msg.replace(msgType, ""))
                console.log('decodedMsg: ', msg)
                decodedMsg = msg

                console.log('generate', generateForm)
                // 发送给后台
                const body = {
                    userId: context.user.id,
                    nickname: context.user.nickname,
                    agentId: agentIdRef.current,
                    generateFormId: generateForm.id,
                    role: 'assistant',
                    content: decodedMsg,
                    createTime: createFormattedDate()
                }

                axiosInstance.post('/chat/send', body).then(res => {
                    console.log('res chat', res)
                    loadHistory()
                }).catch(err => {
                    console.log(err)
                })

                return decodedMsg
            }
            if (msg.includes("stream/started")) {
                console.log(msg)
            }
            else {
                console.log(msg)
            }
        };

        dc.onclose = () => {
            console.log("datachannel close");
        };

        setPeerConnection(pc);
        return answer;
    };

    const handleConnect = async () => {
        console.log('agentId', agentIdRef.current)
        console.log('chatId', chatIdRef.current)
        if (!agentIdRef.current || !chatIdRef.current) {
            alert("请点击 'Create new Agent with Knowledge' 并完成流程");
            return;
        }

        if (peerConnection && peerConnection.connectionState === 'connected') {
            return;
        }

        await closePC();

        const sessionResponse = await axios.post(
            `${DID_API.url}/${DID_API.service}/streams`,
            {
                source_url: idleUrlRef.current,
                config: {
                    stitch: true
                },
                compatibility_mode: 'on'
            },
            {
                headers: {
                    Authorization: `Basic ${DID_API.key}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        const { id: newStreamId, offer, ice_servers: iceServers, session_id: newSessionId } = sessionResponse.data;
        streamIdRef.current = newStreamId;
        sessionIdRef.current = newSessionId;

        try {
            const answer = await createPeerConnection(offer, iceServers);
            await axios.post(
                `${DID_API.url}/${DID_API.service}/streams/${newStreamId}/sdp`,
                {
                    answer,
                    session_id: newSessionId,
                },
                {
                    headers: {
                        Authorization: `Basic ${DID_API.key}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
        } catch (e) {
            console.error('Streaming setup error:', e);
            closePC();
        }
    };

    // 提取常量
    const SEND_CHAT_API = '/chat/send';
    const SEND_AGENT_API = `${DID_API.url}/agents/${agentIdRef.current}/chat/${chatIdRef.current}`;

    // 发送消息函数
    const sendMessage = async (body) => {
        try {
            await axiosInstance.post(SEND_CHAT_API, body);
            console.log('发送聊天消息成功');
        } catch (err) {
            console.error('发送聊天消息失败:', err.message);
        }
    };

    // 处理开始发送
    const handleStart = async () => {
        if (
            peerConnection?.signalingState === 'stable' ||
            peerConnection?.iceConnectionState === 'connected'
        ) {
            const message = messageRef.current.value
            messageRef.current.value = ''

            // 创建消息体
            const messageBody = {
                userId: context.user.id,
                nickname: context.user.nickname,
                agentId: agentIdRef.current,
                generateFormId: generateForm.id,
                role: 'user',
                content: message,
                createTime: createFormattedDate()
            };

            setHistory((prevHistory) => [...prevHistory, messageBody])

            // 发送消息
            try {
                await sendMessage(messageBody);
                // 发送消息到代理
                await axios.post(SEND_AGENT_API, {
                    streamId: streamIdRef.current,
                    sessionId: sessionIdRef.current,
                    messages: [
                        {
                            role: 'user',
                            content: message,
                            created_at: new Date().toString(),
                        },
                    ],
                }, {
                    headers: {
                        'Authorization': `Basic ${DID_API.key}`,
                        'Content-Type': 'application/json',
                    },
                });
                // loadHistory()

                console.log('发送代理消息成功');
            } catch (err) {
                console.error('发送代理消息失败:', err.message);
            }
        }
    };

    // 提取常量
    const STATUS_OK = 200;
    const MAX_RETRY_ATTEMPTS = 3;

    // 创建聊天功能
    const createChat = async () => {
        const attempt = 1; // 当前尝试次数

        const createChatWithRetry = async () => {
            try {
                if (agentIdRef.current) {
                    const response = await axios.post(
                        `${DID_API.url}/agents/${agentIdRef.current}/chat`,
                        {},
                        {
                            headers: {
                                'Authorization': `Basic ${DID_API.key}`,
                                'Content-Type': 'application/json',
                            },
                        }
                    );
                    console.log('create chat res', response)
                    if (response.status === STATUS_OK) {
                        chatIdRef.current = response.data.id;
                        await handleConnect();
                        return;
                    }
                }
            } catch (error) {
                console.error('创建聊天时出错:', error.message);
            }

            // 如果达到最大重试次数，停止尝试
            if (attempt >= MAX_RETRY_ATTEMPTS) {
                console.error('创建聊天失败，已达到最大重试次数');
                return;
            }

            // 等待一段时间后再重试
            setTimeout(() => {
                createChatWithRetry();
            }, 1000 * Math.pow(2, attempt)); // 延迟时间随着重试次数指数增加
        };

        await createChatWithRetry();
    };

    return (
        <>
            <Spin spinning={loading} percent={'auto'} fullscreen={true} tip={'your digitatwin is coming...'} rootClassName='bg-[rgba(6,13,32)]' />
            {!done ? (
                <div className=" flex flex-1 flex-col justify-center items-center px-6 py-12 lg:px-8 w-full h-full">
                    <div className="
                            bg-[rgba(6,13,32,0.7)] rounded-[12px]
                            w-full h-full
                            md:w-2/3 md:h-3/4
                            jusify-center items-center
                            flex flex-col
                            ">
                        <img src={waiting} alt="waiting..." className='w-32 h-32 mt-10' />

                        <h2 className="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-white">
                            WAIT <span className="text-[#40F6FC]">FOR...</span>
                        </h2>

                        <div className="mt-2 h-0.5 w-8 bg-white flex "></div>
                        <div className="mt-10 block flex jusify-start items-center flex-col">
                            <h2 className='mt-2 text-base text-center leading-9 tracking-tight text-white'>
                                Hi, {context.user.nickname},Your generation is in progress, please wait patiently.
                            </h2>
                            <h2 className='mt-2 text-base text-center leading-9 tracking-tight text-white'>
                                When the generation is completed, we will send a notification to your email
                            </h2>
                            <h2 className='mt-2 text-base text-center leading-9 tracking-tight text-white'>Thanks</h2>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='flex flex-col justify-center items-center w-full h-full'>
                    <div className='relative flex flex-col h-full md:max-h-full md:h-full bg-[rgba(6,13,32,0.8)] w-10/12 md:w-2/6 rounded-[8px]'>
                        {/* <!-- 使用aspect-w-1和aspect-h-1来确保宽高比为1:1 --> */}
                        <div className="aspect-w-1 aspect-h-1 w-full">
                            <div id='video' className="w-full h-full p-6">
                                <video
                                    ref={videoElementRef}
                                    id='video-element'
                                    className='rounded-[6px] w-full h-full object-contain bg-top bg-no-repeat animated'
                                    autoPlay loop muted playsInline>
                                    <source
                                        src={idleVideoRef.current}
                                        type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                        <div className="flex flex-col grow w-full min-h-0 md:min-h-0"> {/* 聊天部分 */}
                            <div
                                ref={chatContainerRef}
                                className='bg-[rgba(9,17,39,0.8)] rounded-t-[12px] p-4 overflow-auto hide-scrollbar'
                            >
                                {
                                    history.map((item, index) => (
                                        <ChatBubble
                                            key={index}
                                            direction={item.role === 'user' ? 'right' : 'left'}
                                            message={item.content}
                                            user={item.role === 'user' ? context.user.nickname : generateForm.fullName}
                                            time={item.createTime}
                                            avatar={item.role === 'user' ? context.user.avatar : generateForm.idleUrl}
                                        />
                                    ))
                                }
                            </div>
                            <div className="flex flex-row h-20 justify-center items-center bg-[rgba(9,17,39,0.8)]">
                                <div className="relative w-10/12">
                                    <input
                                        ref={messageRef}
                                        id="message"
                                        name="message"
                                        type="text"
                                        placeholder="type your message"
                                        className="h-10/12 w-full bg-[#2F4167] pr-20 rounded-[8px] border-0 py-1.5 text-white placeholder:text-[#DEF1FF] focus:ring-1 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6 indent-2"
                                    />
                                    <button
                                        type="button"
                                        onClick={handleStart}
                                        className="absolute right-1 top-1/2 transform -translate-y-1/2 h-8 w-20 rounded-[8px] bg-[#40F6FC] hover:bg-[#60FAFF] active:bg-[#04CCD3] px-3  text-sm font-semibold leading-6 text-[#18191D]"
                                    >
                                        send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            )
            }
        </>
    );
}
