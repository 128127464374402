import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/request";
import logo from '../../assets/logo.png'
import PrimaryButton from "../../components/primary-button";
import { message } from "antd";

export default function SignUp() {

    const navigate = useNavigate();

    const nicknameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const [loading, setLoading] = useState(false)

    async function signUp(event) {
        event.preventDefault();
        setLoading(true);

        const nickname = nicknameRef.current?.value;
        const email = emailRef.current?.value;
        const password = passwordRef.current?.value;
        const passwordConfirm = passwordConfirmRef.current?.value;

        // 基本验证
        if (!nickname || !email || !password || !passwordConfirm) {
            message.error('All fields are required');
            return;
        }

        if (password !== passwordConfirm) {
            message.error('Passwords do not match');
            return;
        }

        try {
            const response = await axiosInstance.post("/signup", {
                nickname: nickname,
                username: email,
                password: password,
            });

            console.log("res==", response)

            if (response.data.code === 200) {
                message.success('Signup successful');
                navigate("/login");
            } else {
                message.error(response.data.message);
            }
        } catch (error) {
            console.error('Error during signup:', error);
            message.error('Signup failed: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center items-center px-6 md:py-12 lg:px-8">
                <div className="bg-[rgba(6,13,32,0.7)] rounded-[12px] w-full h-4/5 md:w-[520px] md:h-[750px] flex flex-col items-center overflow-y-auto">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img
                            className="mx-auto h-100 w-auto"
                            src={logo}
                            alt="Muse SYN"
                        />
                    </div>

                    <div className="sm:mx-auto w-full sm:w-full sm:max-w-sm">
                        <form className="space-y-6 max-md:p-6" action="#" method="POST">
                            <div>
                                <label htmlFor="nickName" className="block text-sm font-medium leading-6 text-white">
                                    Nickname
                                </label>
                                <div className="mt-2">
                                    <input
                                        ref={nicknameRef}
                                        id="nickName"
                                        name="nickName"
                                        type="text"
                                        required
                                        placeholder="Please enter your nickName"
                                        className="bg-transparent focus:ring-1 focus:ring-inset focus:ring-white block w-full rounded-[8px] border border-[rgba(255,255,255,0.2)] py-1.5 text-white placeholder:text-[rgba(222,241,255,0.5)] sm:text-sm sm:leading-6 indent-2"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        ref={emailRef}
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        placeholder="Please enter your eamil address"
                                        required
                                        className="bg-transparent focus:ring-1 focus:ring-inset focus:ring-white block w-full rounded-[8px] border border-[rgba(255,255,255,0.2)] py-1.5 text-white placeholder:text-[rgba(222,241,255,0.5)] sm:text-sm sm:leading-6 indent-2"
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                                        Password
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <input
                                        ref={passwordRef}
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        placeholder="Please enter your password"
                                        required
                                        className="bg-transparent focus:ring-1 focus:ring-inset focus:ring-white block w-full rounded-[8px] border border-[rgba(255,255,255,0.2)] py-1.5 text-white placeholder:text-[rgba(222,241,255,0.5)] sm:text-sm sm:leading-6 indent-2"
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="repassword"
                                        className="block text-sm font-medium leading-6 text-white">
                                        Confirm Password
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <input
                                        ref={passwordConfirmRef}
                                        id="passwordConfirm"
                                        name="passwordConfirm"
                                        type="password"
                                        autoComplete="current-password"
                                        placeholder="Please confirm password"
                                        required
                                        className="bg-transparent focus:ring-1 focus:ring-inset focus:ring-white block w-full rounded-[8px] border border-[rgba(255,255,255,0.2)] py-1.5 text-white placeholder:text-[rgba(222,241,255,0.5)] sm:text-sm sm:leading-6 indent-2"
                                    />
                                </div>
                            </div>

                            <div>
                                <PrimaryButton
                                    type="submit"
                                    loading={loading}
                                    onClick={signUp}
                                >
                                    Submit
                                </PrimaryButton>
                            </div>

                            <p className="mt-2 text-center text-sm text-white">
                                Already have an account? {' '}
                                <Link to={'/login'} className="font-semibold leading-6 text-[#40F6FC] hover:underline">Sign in</Link>
                            </p>
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}
