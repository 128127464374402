import React, { forwardRef } from 'react';

const StyledSelect = forwardRef(({ options, placeholder, ...props }, ref) => {
    return (
        <div className="relative mt-2">
            <select
                {...props}
                ref={ref}
                className="bg-transparent focus:ring-1 focus:ring-inset focus:ring-white block w-full rounded-lg border border-[rgba(255,255,255,0.2)] py-2 pl-2 pr-10 text-white text-sm leading-5 sm:text-sm sm:leading-6 appearance-none"
                defaultValue=""
            >
                <option value="" disabled hidden>{placeholder}</option>
                {options.map((option, index) => (
                    <option
                        key={index}
                        value={option.value}
                        className="bg-gray-900 text-white"
                    >
                        {option.label}
                    </option>
                ))}
            </select>
            {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
                <svg className="h-4 w-4 fill-current" viewBox="0 0 20 20">
                    <path d="M7 7l3-3 3 3H7z" />
                </svg>
            </div> */}
        </div>
    );
});

export default StyledSelect;
