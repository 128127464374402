import React, { useState, forwardRef } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import './photo-wall-upload.module.css';
import { Space, message } from 'antd';

const PhotoWallUpload = forwardRef((props, ref) => {

    const placeholder = props.placeholder || 'Upload';
    const limit = props.limit || 3;
    const onFilesChange = props.onFilesChange || function () { };

    const [images, setImages] = useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        if (images.length + files.length > limit) {
            message.error(`Exceeded the limit on the number of uploaded files（${limit}）`);
            return;
        }
        const newImages = files.map((file) => ({ url: URL.createObjectURL(file), file }));
        const updatedImages = [...images, ...newImages];
        onFilesChange(updatedImages.map(img => img.file)); // Return only file objects to the parent component
        setImages(updatedImages);
    };

    const removeImage = (image) => {
        const updatedImages = images.filter((img) => img.url !== image.url);
        onFilesChange(updatedImages.map(img => img.file)); // Return only file objects to the parent component
        setImages(updatedImages);
    };

    return (
        <div className="mt-2">
            <div className="flex flex-wrap gap-4">
                <Space>
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className="relative w-24 h-24 border border-[rgba(255,255,255,0.2)] hover:border-[rgba(255,255,255,0.8)] rounded-lg overflow-hidden group"
                        >
                            <img
                                src={image.url}
                                alt={`Uploaded ${index}`}
                                className="object-cover w-full h-full"
                            />
                            <button
                                onClick={() => removeImage(image)}
                                className="absolute w-full h-full top-0 right-0 bg-[rgba(0,0,0,0.5)] text-white opacity-0 group-hover:opacity-100 transition-opacity"
                            >
                                <DeleteOutlined />
                            </button>
                        </div>
                    ))}
                    {images.length < 3 &&
                        <label className="w-24 h-24 flex items-center justify-center border border-[rgba(255,255,255,0.2)] hover:border-[rgba(255,255,255,0.8)] rounded-lg cursor-pointer bg-transparent text-white">
                            <input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                className="hidden"
                                accept="image/*"
                            />
                            <span className="text-sm">{placeholder || 'Upload'}</span>
                        </label>
                    }
                </Space>
            </div>
        </div>
    );
});

export default PhotoWallUpload;
