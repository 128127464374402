import React, { useContext } from 'react';
import { Dropdown, Space, Avatar } from 'antd';
import AuthContext from "../../../utils/AuthContext";

const Header = () => {
    const userContext = useContext(AuthContext);
    const { logout } = useContext(AuthContext)


    if (!userContext.user) {
        return null
    }

    const handleMenuClick = (e) => {
        if (e.key === "layout") {
            // Handle the "Layout" option click here
            console.log("Layout option clicked");
            logout()
        }
    };

    const items = [
        {
            key: 'layout',
            label: 'Layout',
        }
    ];

    return (
        <div className="flex w-full justify-end p-2 pr-8">
            <Dropdown
                menu={{
                    items,
                    onClick: handleMenuClick,
                }}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        <Avatar style={{ backgroundColor: '#000000', cursor: 'pointer' }} size={'large'}>
                            {userContext.user.nickname}
                        </Avatar>
                    </Space>
                </a>
            </Dropdown>
        </div>
    );
};

export default Header;
