import axios from 'axios';

// 创建 axios 实例
const axiosInstance = axios.create({
    // baseURL: 'http://localhost:8000',
    // baseURL: 'http://120.48.128.166:8000',
    baseURL: 'https://musesyn.com/api/',
    // 其他通用设置...
});

// 请求拦截器
axiosInstance.interceptors.request.use(
    config => {
        // 在这里添加登录令牌等
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
    response => {
        const data = response.data
        console.log('响应数据', data)
        if (data.code === 401) {
            console.log('鉴权失败')
            throw data
        }
        // 对响应数据做点什么
        return response;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
