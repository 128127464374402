// ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from './AuthContext';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { user } = useContext(AuthContext);
    console.log(user)
    return user ? <Component /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
