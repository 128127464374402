import { useRef, useState } from "react";
import { message } from "antd";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/request";
import CustomSelect from '../../components/custom-select/index'
import PhotoWallUpload from '../../components/photo-wall-upload/index'
import PrimaryButton from '../../components/primary-button/index'

const sexOptions = [
    { value: '0', label: 'Female' },
    { value: '1', label: 'Male' },
];

export default function GenerateForm() {

    const navigate = useNavigate()

    const nameRef = useRef(null);
    const sexRef = useRef(null);
    const fileInputRef = useRef(null);

    const [images, setImages] = useState([]);
    const [voiceFiles, setVoiceFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const onFilesChange = (files) => {
        console.log(files)
        setImages([...files]);
    }

    const onVoiceFilesChange = (event) => {
        setVoiceFiles([...event.target.files]);
    }

    const handleCustomFileUploadClick = () => {
        fileInputRef.current.click();
    }

    async function submitHandler(event) {
        // 组织默认提交行为
        event.preventDefault();
        setLoading(true)

        const name = nameRef.current.value;
        console.log(sexRef.current)
        const sex = sexRef.current.value;

        // 验证表单
        if (!name || !sex || !images.length || !voiceFiles.length) {
            toast.error('Please fill in all fields', {})
            setLoading(false)
            return;
        }

        // 构建 FormData 对象
        const formData = new FormData();
        // 将 Blob 对象转换为 File 对象并添加到 formData
        const filePromises = images.map(async (image, index) => {
            // const fileName = `avatar${index + 1}.png`;

            // 将 Blob 转换为 File 对象
            // const file = new File([imageBlob], fileName, { type: imageBlob.type });
            formData.append('files', image);
        });


        const voiceFilePromises = voiceFiles.map(async (voiceFile) => {
            formData.append('voice', voiceFile);
        });


        // 等待所有文件上传完成后继续
        await Promise.all([...filePromises, ...voiceFilePromises]);

        // 添加其他字段
        formData.append('fullName', name);
        formData.append('sex', sex);

        const response = await axiosInstance.post("/generate-form/submit", formData, {
            headers: {
                'Content-Type': 'multipart/form-data' // 设置请求头
            }
        });

        const { code } = response.data;

        if (code === 200) {
            // console.log({ name, sex })
            message.success('your request is being processed', {})
            setLoading(false)
            // 跳转到主页
            navigate('/')
        } else {
            message.error(response.data.message)
            setLoading(false)
        }
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center items-center px-6 md:py-12 lg:px-8">
            <div className="bg-[rgba(6,13,32,0.7)] rounded-[12px] w-full h-[90vh] md:w-[600px] md:h-[744px] flex flex-col items-center overflow-y-auto ">
                <h2 className="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-white">
                    FILL IN <span className="text-[#40F6FC]">INFORMATION</span>
                </h2>
                <h2 className="text-center text-s leading-9 tracking-tight text-[rgba(222,241,255,0.5)]">
                    Please enter informationfor your digitatwin
                </h2>
                <div className="mt-2 h-0.5 w-8 bg-white flex"></div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm w-full">
                    <form className="space-y-6 max-md:p-6" action="#" method="POST">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                                Full name
                            </label>
                            <div className="mt-2">
                                <input
                                    ref={nameRef}
                                    id="name"
                                    name="name"
                                    type="text"
                                    required
                                    className="bg-transparent focus:ring-1 focus:ring-inset focus:ring-white block w-full rounded-[8px] border border-[rgba(255,255,255,0.2)] py-1.5 text-white placeholder:text-[rgba(222,241,255,0.5)] sm:text-sm sm:leading-6 indent-2"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="sex" className="block text-sm font-medium leading-6 text-white">
                                Sex
                            </label>
                            <div className="mt-2">
                                <CustomSelect
                                    ref={sexRef}
                                    id="sex"
                                    name="sexOptions"
                                    placeholder="Select sex"
                                    options={sexOptions}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-white">
                                Avatar
                            </label>
                            <div className="mt-2">
                                <PhotoWallUpload
                                    name="avatar"
                                    required
                                    placeholder="add avatar"
                                    onFilesChange={onFilesChange}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="voice" className="block text-sm font-medium leading-6 text-white">
                                Voice
                            </label>
                            <div className="mt-2">
                                <input
                                    id="voice"
                                    name="voice"
                                    type="file"
                                    accept="audio/*"
                                    multiple
                                    required
                                    ref={fileInputRef}
                                    className="hidden"
                                    onChange={onVoiceFilesChange}
                                />
                                <button
                                    type="button"
                                    onClick={handleCustomFileUploadClick}
                                    className="bg-transparent block w-full rounded-[8px] border border-[rgba(255,255,255,0.2)] py-1.5 text-white text-left pl-2 sm:text-sm sm:leading-6"
                                >
                                    Click to upload voice files
                                </button>
                            </div>
                            {voiceFiles.length > 0 && (
                                <div className="mt-2 text-white text-sm">
                                    <strong>Selected files:</strong>
                                    <ul>
                                        {voiceFiles.map((file, index) => (
                                            <li key={index}>{file.name}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>

                        <div>
                            <PrimaryButton
                                type="submit"
                                onClick={submitHandler}
                                loading={loading}

                            >
                                Submit
                            </PrimaryButton>
                            {/* <button
                                type="submit"
                                onClick={submitHandler}
                                className="flex mt-10 w-full justify-center rounded-[8px] bg-[#40F6FC] px-3 py-1.5 text-sm font-semibold leading-6 text-[#18191D]"
                            >
                                Submit
                            </button> */}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

