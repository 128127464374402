export default function ChatBubble(props) {
    const { user, message, time, direction, avatar } = props;

    const isLeft = direction === 'left';

    // 公共的聊天气泡内容
    const bubbleContent = (
        <div className="flex flex-col w-full max-w-[350px] p-4 border-gray-200 bg-[#2F4167] rounded-lg">
            <p className="text-sm font-normal text-white">{message}</p>
        </div>
    );

    // 根据 isLeft 决定布局
    return (
        <div className={`flex items-start gap-2.5 mt-2 ${isLeft ? '' : 'flex-row-reverse'}`}>
            {avatar ? (
                <img
                    className="w-8 h-8 rounded-full"
                    src={avatar}
                    alt="avatar"
                />
            ) : (
                <div className="w-8 h-8 font-bold text-sm rounded-full bg-black flex justify-center items-center text-white">
                    {user.charAt(0)}
                </div>
            )}
            <div className={`flex flex-col ${isLeft ? 'text-left' : 'text-right'}`}>
                <span className="text-sm font-semibold text-white mb-1">{user}</span>
                {bubbleContent}
            </div>
        </div >
    );
}
