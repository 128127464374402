const padZero = (num) => num.toString().padStart(2, '0');

const createFormattedDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = padZero(now.getMonth() + 1); // 月份从0开始，需要加1
    const day = padZero(now.getDate());
    const hours = padZero(now.getHours());
    const minutes = padZero(now.getMinutes());
    const seconds = padZero(now.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
export default createFormattedDate;