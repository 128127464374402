import React from 'react';
import { Spin } from 'antd';

const PrimaryButton = ({ loading = false, onClick, children }) => {
    return (
        <Spin spinning={loading}>
            <button
                onClick={onClick}
                className="flex w-full justify-center rounded-[8px] bg-[#40F6FC] hover:bg-[#60FAFF] active:bg-[#04CCD3] px-3 py-1.5 text-sm font-semibold leading-6 text-[#18191D]"
            >
                {children}
            </button>
        </Spin>
    );
};

export default PrimaryButton;
