import { useContext, useRef, useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import AuthContext from "../../utils/AuthContext";
import axiosInstance from "../../utils/request";
import logo from '../../assets/logo.png'
import PrimaryButton from "../../components/primary-button";
import { message } from "antd";

export default function Login() {

    const navigate = useNavigate()

    const [loading, setLoding] = useState(false)

    const emailRef = useRef();
    const passwordRef = useRef();
    const { login } = useContext(AuthContext);

    const loginHandler = async (event) => {
        event.preventDefault();
        setLoding(true)
        try {

            let username = emailRef.current?.value
            let password = passwordRef.current?.value

            if (!username || !password) {
                message.error({
                    content: 'Please enter username and password',
                });
                return;
            }

            const response = await axiosInstance.post("/login", {
                username: emailRef.current?.value,
                password: passwordRef.current?.value,
            });

            console.log("res==", response)

            if (response.data.code === 200) {
                message.success('Login successful');
                login(response.data.data);

                try {
                    axiosInstance.get("/generate-form/already").then((response) => {
                        const { code, data } = response.data
                        if (code === 200 && data) {
                            // 跳转到主页
                            console.log('跳转到主页')
                            navigate('/', { replace: true })
                        } else {
                            navigate("/generate-form", { replace: true });
                        }
                    })
                } catch {
                    message.error('network error, please try again later')
                }
            } else {
                message.error(response.data.message);
            }
        } catch (error) {
            message.error('Login failed: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoding(false)
        }
    };

    return (
        <>

            <div className="flex min-h-full flex-1 flex-col justify-center items-center px-6 md:py-12 lg:px-8">
                <div className="bg-[rgba(6,13,32,0.7)] rounded-[12px] w-full h-4/5 pb-2 md:w-[520px] md:h-[644px] overflow-y-auto">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img
                            className="mx-auto h-100 w-auto"
                            src={logo}
                            alt="Muse SYN"
                        />
                    </div>
                    <div className="sm:px-4 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form className="space-y-6 max-md:p-6" action="#">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        ref={emailRef}
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        placeholder="Please enter your eamil address"
                                        required
                                        className="bg-transparent focus:ring-1 focus:ring-inset focus:ring-white block w-full  rounded-[8px] border border-[rgba(255,255,255,0.2)] py-1.5 text-white placeholder:text-[rgba(222,241,255,0.5)] sm:text-sm sm:leading-6 indent-2"
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                                        Password
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <input
                                        ref={passwordRef}
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        placeholder="Please enter your password"
                                        required
                                        className="bg-transparent focus:ring-1 focus:ring-inset focus:ring-white block w-full rounded-[8px] border border-[rgba(255,255,255,0.2)] py-1.5 text-white placeholder:text-[rgba(222,241,255,0.5)] sm:text-sm sm:leading-6 indent-2"
                                    />
                                </div>
                            </div>

                            <div className="mt-5">
                                <PrimaryButton
                                    loading={loading}
                                    onClick={loginHandler}
                                >
                                    Sign in
                                </PrimaryButton>
                            </div>
                        </form>

                        <p className="mt-5 text-center text-sm text-gray-500">
                            <Link to={'/'} className="font-semibold leading-6 text-[#40F6FC] hover:underline">Forgot password</Link>
                        </p>
                        <p className="mt-2 text-center text-sm text-white">
                            Not a member?{' '}
                            <Link to={'/signup'} className="font-semibold leading-6 text-[#40F6FC] hover:underline">Sign
                                Up</Link>
                        </p>
                    </div>
                </div >

            </div >
        </>
    )
}
