import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from './request';

const withAuth = (WrappedComponent) => {
    const WithAuthComponent = (props) => {
        const navigate = useNavigate();
        const location = useLocation()

        useEffect(() => {

            if (location.pathname === '/login' || location.pathname === '/signup') {
                return; // 如果当前路由是 login 或 signup，不执行检查逻辑
            }

            const checkAuth = async () => {
                try {
                    // 使用axios发送请求
                    await axiosInstance.get('/isLogin');
                    // 如果请求成功，继续渲染原始组件
                } catch (error) {
                    // 捕获错误并处理
                    console.log('withAuth error', error)
                    if (error && error.code === 401) {
                        // 跳转到登录页面
                        navigate('/login');
                    } else {
                        // 其他错误处理逻辑
                    }
                }
            };

            checkAuth();
        }, [location, navigate]);

        return <WrappedComponent {...props} />;
    };

    return WithAuthComponent;
};

export default withAuth;
